<template>
  <header
    class="subject-header"
    :style="{
      backgroundColor: subject.ready
        ? subject.data.color
        : '#e0e4ea'
    }"
  >
    <wrapper>
      <div class="subject-header__text">
        <s-button
          variation="tertiary"
          :color="$tokens.color_white"
          icon="chevron-left"
          size="large"
          :to="{name: 'home'}"
        />
        <h3
          v-if="subject.ready"
          class="subject-header__text__title"
        >
          {{ subject.data.name }}
        </h3>
        <skeleton-loader
          v-else
          width="200px"
          height="38px"
        />
      </div>
      <div class="subject-header__tabs">
        <Tabs
          v-if="tabRoutes.length > 1"
          :tabs="tabRoutes"
          theme="dark"
        />
        <TabsSkeleton v-if="tabRoutes.length === 0" />
      </div>
    </wrapper>
  </header>
</template>

<script>
import { mapGetters } from 'vuex'

import Tabs from '@/components/Tabs'
import TabsSkeleton from '@/components/TabsSkeleton'

export default {
  name: 'SubjectHeader',
  components: {
    Tabs,
    TabsSkeleton,
  },
  computed: {
    ...mapGetters([
      'subject',
      'subjectLectures',
    ]),
    lectureId() {
      return parseInt(this.$route.params.lectureId, 10)
    },
    tabRoutes() {
      if (!this.subjectLectures.ready) {
        return []
      }

      return this.subjectLectures.data.map((lecture) => ({
        name: lecture.name,
        route: {
          name: 'lecture-assignments',
          params: {
            lectureId: lecture.id,
          },
        },
      }))
    },
  },
}
</script>

<style lang="sass" scoped>
$shadow: linear-gradient(215deg, rgba(0, 0, 0, 0) 25%, rgba(0, 0, 0, 0.25) 100%)

.subject-header
  position: relative
  transition: background-color $speed-x-slow ease

  &::before
    +cover(absolute)
    +pseudo
    background: $shadow

  &__text
    position: relative
    +flex-center-start
    padding: $size-xl 0
    color: $color-white
    font-size: $font-size-heading-3
    z-index: 1

    &__title
      margin-left: $size-xs
</style>
