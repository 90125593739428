<template>
  <div class="subject">
    <SubjectHeader />
    <wrapper class="subject__wrapper">
      <AssignmentsSectionSkeleton v-if="subjectLectures.loading" />
      <router-view v-else />
    </wrapper>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import subject from '@/mixins/subject'
import AssignmentsSectionSkeleton from '@/components/Assignments/AssignmentsSectionSkeleton'
import SubjectHeader from './partials/SubjectHeader'

export default {
  name: 'Subject',
  components: {
    AssignmentsSectionSkeleton,
    SubjectHeader,
  },
  mixins: [ subject ],
  data() {
    return {
      selectedLecture: null,
    }
  },
  computed: {
    ...mapGetters([
      'subjectLectures',
    ]),
    lectureId() {
      return parseInt(this.$route.params.lectureId, 10)
    },
  },
  watch: {
    selectedLecture() {
      this.setLecture(this.selectedLecture)
    },
  },
  created() {
    this.fetchLectures()
  },
  methods: {
    ...mapActions([
      'getSubjectLectures',
      'setLecture',
    ]),
    async fetchLectures() {
      if (!this.subjectLectures.data?.some(({ id }) => id === this.lectureId)) {
        await this.getSubjectLectures(this.subjectId)
      }
      if (this.$route.name !== 'lecture-assignments') {
        [ this.selectedLecture ] = this.subjectLectures.data

        this.$router.replace({
          name: 'lecture-assignments',
          params: {
            lectureId: this.selectedLecture.id,
          },
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.subject {
  .header {
    min-height: 170px;
    width: 100%;
    background-color: purple;
    color: $color-white;
    margin-bottom: $size-xl;
    text-align: center;
  }

  &__wrapper {
    padding: {
      top: $size-l;
      bottom: $end-space;
    }
  }
}
</style>
