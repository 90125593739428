<template>
  <div class="assignment-section-skeleton">
    <skeleton-loader
      class="assignment-section-skeleton__title"
      width="200px"
      height="38px"
    />
    <skeleton-loader
      class="assignment-section-skeleton__subtitle"
      width="150px"
      height="19px"
    />
    <AssignmentsListSkeleton />
  </div>
</template>

<script>
import AssignmentsListSkeleton from './AssignmentsListSkeleton'

export default {
  name: 'AssignmentsSectionSkeleton',
  components: {
    AssignmentsListSkeleton,
  },
}
</script>

<style lang="sass" scoped>
.assignment-section-skeleton
  margin-bottom: $size-xl

  &__title
    margin-bottom: $size-xs

  &__subtitle
    margin-bottom: $size-m
</style>
