<template>
  <div class="tabs-skeleton">
    <skeleton-loader
      v-for="index of 3"
      :key="index"
      width="80px"
      height="20px"
      opacity="0.5"
    />
  </div>
</template>

<script>
export default {
  name: 'TabsSkeleton',
}
</script>

<style lang="sass" scoped>
.tabs-skeleton
  display: flex

  .sas-skeleton-loader
    margin: 8px $size-xs
    +space-inline($size-m)
</style>
