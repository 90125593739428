<template>
  <div
    ref="tabsParent"
    :class="['tabs', `--${theme}`]"
  >
    <template v-if="isRouteMode">
      <s-button
        v-for="(tab, index) in tabs"
        :key="index"
        :data-name="tab.name"
        class="tabs__item"
        variation="tertiary"
        :to="tab.route"
        @click.native="scrollToActive($event.target)"
      >
        {{ tab.name }}
      </s-button>
    </template>
    <template v-else>
      <s-button
        v-for="(tab, index) in tabs"
        :key="index"
        :class="[ 'tabs__item', { '--active': tab.isActive } ]"
        :data-name="tab.name"
        variation="tertiary"
        @click.native="selectTab($event.target, tab)"
      >
        {{ tab.name }}
      </s-button>
    </template>
  </div>
</template>

<script>
export default {
  name: 'Tabs',
  props: {
    isRouteMode: {
      type: Boolean,
      default: true,
    },
    theme: {
      type: String,
      default: 'light',
      validator: (value) => value.match(/(light|dark)/),
    },
    tabs: {
      type: Array,
      default: () => [],
    },
  },
  mounted() {
    const activeTab = document.querySelector('.tabs__item.--active')

    if (activeTab) {
      this.scrollToActive(activeTab)
    }
  },
  methods: {
    selectTab(target, tab) {
      this.scrollToActive(target)

      this.$emit('select', tab)
    },
    scrollToActive(element) {
      const { tabsParent } = this.$refs
      const tab = element.classList.contains('tabs__item')
        ? element
        : element.parentNode

      tabsParent.scrollBy({
        left: tab.clientWidth + tab.offsetLeft - tabsParent.clientWidth,
        behavior: 'smooth',
      })
    },
  },
}
</script>

<style lang="sass" scoped>
.tabs
  overflow: auto
  +flex-center-start

  &__item
    +space-inline($size-s)
    border-radius: 0
    flex-shrink: 0

    &::after
      position: absolute
      bottom: 0
      content: ''
      display: block
      height: 5px
      width: 0%
      border-radius: 10px 10px 0 0
      background: $color-primary-dark
      +transition(.3s)

    ::v-deep .sas-button__text
      color: $color-ink-light

    &.--active,
    &.router-link-active
      &::after
        width: 100%

      ::v-deep .sas-button__text
        color: $color-ink

  &.--dark
    .tabs__item
      &::after
        background-color: $color-white

      &.--active,
      &.router-link-active
        ::v-deep .sas-button__text
          color: $color-white
          opacity: 1
          font-size: $font-size-s
          line-height: 20px

      ::v-deep .sas-button__text
        color: $color-white
        opacity: 0.7
</style>
