import { mapGetters, mapActions } from 'vuex'

export default {
  computed: {
    ...mapGetters([
      'subject',
    ]),
    subjectId() {
      return parseInt(this.$route.params.subjectId, 10)
    },
  },
  created() {
    if (this.subjectId) {
      this.getSubject(this.subjectId)
    }
  },
  methods: {
    ...mapActions([
      'getSubject',
    ]),
  },
}
